<template>
  <div class="col-12 mt-16 mb-3">
    <div class="overflow-auto">
      <div class="mt-16">
        <b-pagination
          @input="onPageChanged"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="total_rows"
          align="right"
          class="m-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";

export default {
  name: "PaginatePengguna",
  props: ["perPage", "total_rows", "currentPage", "page", "onPageChanged"],
  components: {
    BPagination,
  },
};
</script>
