<template>
  <div class="bg-black-0 rounded mx-0 p-sm-32">
    <div class="overflow-hidden mb-sm-32 w-100">
      <h2>Daftar Pendonor</h2>
      <span class="list-total hp-p1-body">Semua ( {{ table.total }} )</span>
      <div
        class="header d-flex justify-content-between mt-1"
        style="gap: 0.5rem"
      >
        <div>
          <router-link to="/dashboards/user/add-user">
            <b-button variant="primary-2" class="add-btn">
              Tambah Daftar
            </b-button>
          </router-link>
        </div>
        <div class="search-container">
          <b-input-group>
            <b-form-input
              v-model="table.name"
              type="search"
              placeholder="Search"
              @input="debounceSearch"
              class="search-input"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>

    <TableListPengguna :is-busy="isBusy" :items="table.items" />
    <PaginatePengguna
      :current-page="table.currentPage"
      :per-page="table.perPage"
      :total_rows="table.total"
      :on-page-changed="onPageChange"
    />
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormInput } from "bootstrap-vue";
import TableListPengguna from "./components/TableListPengguna.vue";
import PaginatePengguna from "./components/PaginatePengguna.vue";
import PendonorDataService from "../../../../api/manageuser/manageUserAPI";
import tableDataMixins from "../../../../mixins/tableDataMixins";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    TableListPengguna,
    PaginatePengguna,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      lengthData: null,
      isBusy: false,
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      const queryParams = {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.name,
      };

      this.isBusy = true;

      PendonorDataService.getAll(queryParams)
        .then((response) => {
          this.table.items = response.data.data.data;
          this.table.total = response.data.data.total;
          this.isBusy = false;
        })
        .catch((e) => console.error(e));
    },
    onPageChange(newPage) {
      this.table.currentPage = newPage;
      this.fetchUsers();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      if (this.table.name.trim() !== "") {
        this.fetchUsers();
      } else {
        this.fetchUsers();
      }
    },
  },
};
</script>

<style scoped>
.search-container {
  display: flex;
  align-items: center;
  position: relative;
}

.search-input {
  padding-left: 2.5rem;
}

.search-container::before {
  content: "";
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23007bff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 111.41-1.41l4.58 4.58-1.42 1.42-4.57-4.58zM8 14A6 6 0 108 2a6 6 0 000 12z"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none; /* Memastikan ikon tidak dapat diklik */
}

/* mobile screen */
@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
  }

  .add-btn {
    float: right;
  }

  .list-total {
    margin: 0;
  }
}
</style>
