<template>
  <div>
    <b-alert
      class="successAlert"
      :show="dismissCountDown"
      dismissible
      variant="warning"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <h4 class="alert-heading">{{ alertMessage }}</h4>
    </b-alert>

    <b-modal
      id="modal-nonactive"
      size="lg"
      hide-footer
      header-class="align-items-center"
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Apakah anda yakin ingin menonaktifkan user <br />
          <strong>{{ name }}</strong> ini ?
        </h5>

        <b-button
          variant="text"
          @click="close()"
          class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
          style="min-height: 24px"
        >
          <i
            class="ri-close-line hp-text-color-dark-0 lh-1"
            style="font-size: 24px"
          ></i>
        </b-button>
      </template>

      <div class="d-flex justify-content-end flex-wrap">
        <b-button variant="text" @click="$bvModal.hide('modal-nonactive')">
          Close
        </b-button>

        <b-button class="ml-16" variant="primary" @click="confirmNonActive">
          ya, non-aktifkan
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-active"
      size="lg"
      hide-footer
      header-class="align-items-center"
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Apakah anda yakin ingin mengaktifkan user <br />
          <strong>{{ name }}</strong> ini ?
        </h5>

        <b-button
          variant="text"
          @click="close()"
          class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
          style="min-height: 24px"
        >
          <i
            class="ri-close-line hp-text-color-dark-0 lh-1"
            style="font-size: 24px"
          ></i>
        </b-button>
      </template>

      <div class="d-flex justify-content-end flex-wrap">
        <b-button variant="text" @click="$bvModal.hide('modal-active')">
          Close
        </b-button>

        <b-button class="ml-16" variant="primary" @click="confirmActive">
          ya, aktifkan
        </b-button>
      </div>
    </b-modal>

    <div class="col-12 d-flex overflow-auto">
      <b-table
        class="w-100 overflow-auto"
        :items="items"
        :busy="isBusy"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
        show-empty
        empty-text="Data Tidak Ditemukan"
      >
        <template v-slot:cell(aksi)="row">
          <div class="d-flex" style="gap: 0.5rem">
            <b-button
              style="width: 105px"
              size="sm"
              v-if="row.item.status === 'Active'"
              variant="danger-1"
              @click="nonActiveUser(row.item.id)"
            >
              <i class="ri-user-unfollow-line" />
              Non-Aktif
            </b-button>
            <b-button
              style="width: 105px"
              size="sm"
              v-if="row.item.status === 'Non-Active'"
              variant="success"
              @click="activeUser(row.item.id)"
            >
              <i class="ri-user-follow-line" />
              Aktif
            </b-button>
            <router-link :to="`/dashboards/user/detail/${row.item.id}`">
              <b-button variant="primary-1" size="sm">
                <i class="ri-menu-fill" />
                Detail
              </b-button>
            </router-link>

            <b-button
              variant="info-1"
              size="sm"
              :to="`/dashboards/user/edit-user/${row.item.id}`"
            >
              <i class="ri-edit-box-line" />
              Edit
            </b-button>
            <b-button
              variant="warning-1"
              size="sm"
              :disabled="row.item.status === 'Non-Active'"
              @click.stop.prevent
              :to="`/dashboards/user/reservation-user/${row.item.id}`"
            >
              <i class="ri-edit-box-line" />
              Reservasi
            </b-button>
          </div>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #table-empty>
          <div class="hp-p1-body text-center my-2">
            <strong>Data tidak ditemukan</strong>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable, BButton, BSpinner, BAlert, BModal } from "bootstrap-vue";
import PendonorDataService from "@/api/manageuser/manageUserAPI";

export default {
  name: "TableListPengguna",
  components: {
    BTable,
    BButton,
    BSpinner,
    BAlert,
    BModal,
  },
  props: {
    isBusy: Boolean,
    items: Array,
  },
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "email", sortable: true },
        { key: "phone", sortable: true },
        { key: "aksi", sortable: false },
      ],
      idToChange: null,
      name: "",
      alertMessage: "",
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(message) {
      this.alertMessage = message;
      this.dismissCountDown = this.dismissSecs;
    },
    nonActiveUser(id) {
      const user = this.items.find((item) => item.id === id);
      if (user) {
        this.name = user.name;
        this.idToChange = id;
        this.$bvModal.show("modal-nonactive");
      }
    },
    confirmNonActive() {
      this.$bvModal.hide("modal-nonactive");
      this.nonActiveAction(this.idToChange);
    },
    nonActiveAction(id) {
      if (!id) {
        console.error("ID not found");
        return;
      }
      PendonorDataService.nonactive({ id })
        .then((response) => {
          console.log(response);
          this.showAlert("Data pendonor berhasil di non-aktifkan");
          const user = this.items.find((item) => item.id === id);
          if (user) {
            user.status = "Non-Active";
          }
        })
        .catch((err) => console.error(err));
    },
    activeUser(id) {
      const user = this.items.find((item) => item.id === id);
      if (user) {
        this.name = user.name;
        this.idToChange = id;
        this.$bvModal.show("modal-active");
      }
    },
    confirmActive() {
      this.$bvModal.hide("modal-active");
      this.activeAction(this.idToChange);
    },
    activeAction(id) {
      if (!id) {
        console.error("ID not found");
        return;
      }
      PendonorDataService.active({ id })
        .then((response) => {
          console.log(response);
          this.showAlert("Data pendonor berhasil di aktifkan");
          const user = this.items.find((item) => item.id === id);
          if (user) {
            user.status = "Active";
          }
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style scoped>
.successAlert {
  position: fixed;
  top: 0;
  right: 0;
  margin: 16px;
  z-index: 99;
}
</style>
